<template>
	<div>
		<slot id="nuxt--content" />
	</div>
</template>

<script>
export default {
	data() {
		return {
		}
	},
	watch: {
		$route: {
			// dont use immeadeate bc document not defined on ssr server
			handler(to, from) {
				this.checkURL()
			},
		},
	},
	mounted() {
		this.checkURL()

		// if (process.env.IS_PROD) {
		// 	const { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } = require('gsap')
		// 	gsap.config({ nullTargetWarn: false });
		// }
	},
	methods: {
		checkURL() {
			document.body.classList.forEach((c) => {
				if (c.startsWith("body--")) document.body.classList.remove(c)
			})
			const route = this.$route.fullPath.split('/')[1];
			document.body.classList.add("body--" + route)
		}
	},
}
</script>


<style scoped lang="scss">
#nuxt--content {
	min-height: 100vh;
}
</style>
